import { Link, Table } from '@/components';
import {
  Visibility,
  EditSharp,
  DeleteSharp,
  ChangeCircle,
  LocalAtm,
} from '@mui/icons-material';
import { Tooltip, IconButton } from '@mui/material';
import userPhoto from '@/assets/img/usuario.png';
import { useAuth } from '@/providers/auth';
import { useDeleteUser } from '../api';
import { UserValues } from '../types';

interface DesktopTableProps {
  tableData: UserValues[];
  toggleEdit: (id: string) => void;
  toggleDelete: (id: string) => void;
  toggleVoucher: (id: string) => void;
  loading: boolean;
}

export function DesktopTable({
  tableData = [],
  toggleEdit,
  toggleDelete,
  toggleVoucher,
  loading,
}: DesktopTableProps) {
  const { user } = useAuth();
  const hasPermissionToViewSensitiveData = user?.user?.profile?.find(
    data =>
      data.name === 'editar passageiros e visualizar informações sensíveis',
  );
  const { mutateAsync } = useDeleteUser();

  const activeUser = (id: string) => {
    mutateAsync({ id });
  };

  return (
    <Table<UserValues>
      columns={[
        {
          title: 'Foto',
          field: 'urlImage',
          Cell({ entry: { urlImage, name } }) {
            return (
              <>
                {urlImage ? (
                  <img
                    src={urlImage}
                    alt={`Foto do usuário ${name}`}
                    style={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '50%',
                      objectFit: 'cover',
                    }}
                  />
                ) : (
                  <img
                    src={userPhoto}
                    alt="Foto padrão"
                    style={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '50%',
                      objectFit: 'cover',
                    }}
                  />
                )}
              </>
            );
          },
        },
        { title: 'Nome', field: 'name', colSpan: 2 },
        { title: 'Setor', field: 'sectorName', colSpan: 2 },
        { title: 'Telefone', field: 'phone', colSpan: 2 },
        {
          title: 'Ações',
          field: 'id',
          Cell({ entry: { id, status, accessType } }) {
            return (
              <>
                {hasPermissionToViewSensitiveData && (
                  <Tooltip title="Visualizar">
                    <IconButton onClick={() => toggleEdit(id)}>
                      <Visibility sx={{ color: 'gray' }} />
                    </IconButton>
                  </Tooltip>
                )}
                {accessType === 'user' && (
                  <Tooltip title="Voucher">
                    <IconButton onClick={() => toggleVoucher(id)}>
                      <LocalAtm sx={{ color: 'green' }} />
                    </IconButton>
                  </Tooltip>
                )}
                {hasPermissionToViewSensitiveData && (
                  <>
                    {status ? (
                      <>
                        <Link to={`./${id}`}>
                          <Tooltip title="Editar">
                            <IconButton>
                              <EditSharp sx={{ color: 'green' }} />
                            </IconButton>
                          </Tooltip>
                        </Link>

                        <Tooltip title="Inativar">
                          <IconButton onClick={() => toggleDelete(id)}>
                            <DeleteSharp sx={{ color: 'red' }} />
                          </IconButton>
                        </Tooltip>
                      </>
                    ) : (
                      <>
                        <Tooltip title="Reativar">
                          <IconButton onClick={() => activeUser(id)}>
                            <ChangeCircle sx={{ color: 'green' }} />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </>
                )}
              </>
            );
          },
        },
      ]}
      data={tableData?.sort((a, b) => a?.name?.localeCompare(b?.name))}
      loading={loading}
    />
  );
}
