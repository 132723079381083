import { Chart } from 'react-google-charts';

import { Box } from '@mui/material';

interface IProps {
  datasets: {
    title: string;
    amount: number;
    color: string;
  }[];
  height?: number;
  width?: number;
}

export function PieChart3DComponent({
  datasets,
  height = 240,
  width = 220,
}: IProps) {
  const data = [['', ''], ...datasets.map(info => [info.title, info.amount])];
  return (
    <Box>
      <Chart
        chartType="PieChart"
        data={data}
        style={{
          width: '100%',
          height: '100%',
        }}
        options={{
          is3D: true,
          colors: datasets.map(info => info.color),
          legend: 'none',
          height,
          width,
          backgroundColor: 'transparent',
        }}
      />
    </Box>
  );
}
