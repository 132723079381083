/* eslint-disable import/no-duplicates */
import { Modal } from '@/components';
import ptLocale from 'date-fns/locale/pt';
import { DateTimePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import * as z from 'zod';
import { useEffect, useState } from 'react';
import { useNotificationStore } from '@/stores';
import { AddCircle } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { SelectCompany } from '@/features/reports/components/SelectCompanyInput';
import { useCompanies } from '@/features/companyNew';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useListClasses } from '@/features/vehicleClasses';
import { useSendTripAsRenter } from '../../api';
import { SelectFavoriteAddress } from '../SelectFavoriteAdresses';
import { IAddressDTO } from '../../types';
import { AddressInput } from '../AddressInput/AddressInput';
import { StopPoints } from '../TripRequest/StopPoints';
import { SelectTypeOfVehicle } from '../TripRequest/SelectTypeOfVehicle';
import { SelectSector } from './SelectSector';
import { SelectPassengerAsRenter } from './SelectPassenger';
import { CreateSectorAsRenter } from './CreateSectorAsRenter';
import { CreatePassengerAsRenter } from './CreatePassengerAsRenter';
import { SelectClassOfVehicle } from '../TripRequest/SelectClassOfVehicle';

export interface IStopPoint {
  order: number;
  lat: number;
  long: number;
  address: string;
}

const schema = z.object({
  addressFrom: z.string(),
  addressTo: z.string().optional(),
  cityOfOrigin: z.string().optional(),
  cityOfDestination: z.string().optional(),
  startLat: z.number(),
  startLong: z.number(),
  finalLat: z.number().optional(),
  finalLong: z.number().optional(),
  scheduledDate: z.string().optional(),
  sectorId: z.string().optional(),
  vehicleCategoryId: z.string().optional(),
  vehicleClassId: z.string().optional(),
  observation: z.string().optional(),
  clientTripId: z.string().optional(),
});

export const RequestTrip = () => {
  const [observation, setObservation] = useState('');
  const [clientTripId, setClientTripId] = useState('');
  const { addNotification } = useNotificationStore();
  const [isScheduled, setIsScheduled] = useState(false);
  const [scheduledDate, setScheduledDate] = useState<Date | null>();
  const [isReturnScheduled, setIsReturnScheduled] = useState(false);
  const [scheduledReturnDate, setScheduledReturnDate] = useState<Date | null>();
  const [stopPoints, setStopPoints] = useState<IStopPoint[]>([]);
  const [disposition, setDisposition] = useState(false);
  const [needSpecificCar, setNeedSpecificCar] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [userIds, setUserIds] = useState<{ key: number; id: string }[]>([
    {
      key: Date.now(),
      id: '',
    },
  ]);
  interface IStopPointsObservation {
    stopPointId: number;
    observation: string;
  }

  const [stopPointsObservation, setStopPointsObservation] = useState<
    IStopPointsObservation[]
  >([]);

  const {
    isOpen: isCreateSectorOpen,
    open: openCreateSector,
    close: closeCreateSector,
  } = useDisclosure();
  const {
    isOpen: isCreatePassengerOpen,
    open: openCreatePassenger,
    close: closeCreatePassenger,
  } = useDisclosure();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { data: companies } = useCompanies();
  const { data: classes } = useListClasses();

  const { mutateAsync: requestTrip, isLoading: isSendingTrip } =
    useSendTripAsRenter();

  const { handleSubmit, setValue, watch } = useForm({
    resolver: schema && zodResolver(schema),
    defaultValues: {
      sectorId: '',
      vehicleCategoryId: '',
      vehicleClassId: '',
      addressFrom: '',
      addressTo: '',
      startLat: 0,
      startLong: 0,
      finalLat: 0,
      finalLong: 0,
      cityOfDestination: '',
      clientTripId: '',
      cityOfOrigin: '',
      observation: '',
    },
  });

  const addressFrom = watch('addressFrom');
  const addressTo = watch('addressTo');
  const sectorId = watch('sectorId');

  const [selectFavoriteAddress, setSelectFavoriteAddress] = useState({
    open: false,
    handler: (data: IAddressDTO) => {},
  });

  const handleSelectFavoriteAddressToOrigin = (setValue: any) => {
    setSelectFavoriteAddress({
      open: true,
      handler: (address: IAddressDTO) => {
        setValue('addressFrom', address.address);
        setValue('cityOfOrigin', address.city);
        setValue('startLat', address.latitude);
        setValue('startLong', address.longitude);
        setSelectFavoriteAddress({
          open: false,
          handler: (data: IAddressDTO) => {},
        });
      },
    });
  };

  const handleSelectFavoriteAddressToStopPoint = ({
    setStopPoints,
    order,
  }: {
    setStopPoints: React.Dispatch<React.SetStateAction<IStopPoint[]>>;
    order: number;
  }) => {
    setSelectFavoriteAddress({
      open: true,
      handler: (address: IAddressDTO) => {
        const findStopPoint = stopPoints.find(
          stopPoint => stopPoint.order === order,
        );
        if (!findStopPoint) return;
        findStopPoint.address = address.address;
        findStopPoint.lat = address.latitude;
        findStopPoint.long = address.longitude;
        setStopPoints((prev: IStopPoint[]) => [...prev]);
        setSelectFavoriteAddress({
          open: false,
          handler: (data: IAddressDTO) => {},
        });
      },
    });
  };

  const handleSelectFavoriteAddressToDestination = (setValue: any) => {
    setSelectFavoriteAddress({
      open: true,
      handler: (address: IAddressDTO) => {
        setValue('addressTo', address.address);
        setValue('cityOfDestination', address.city);
        setValue('finalLat', address.latitude);
        setValue('finalLong', address.longitude);
        setSelectFavoriteAddress({
          open: false,
          handler: (data: IAddressDTO) => {},
        });
      },
    });
  };

  const handleAddressFrom = (val: any, setValue: any) => {
    geocodeByAddress(val?.label)
      .then(results => {
        const city = results[0].address_components.find(item =>
          item.types.includes('administrative_area_level_2'),
        );
        const city2 = results[0].address_components.find(item =>
          item.types.includes('administrative_area_level_1'),
        );
        setValue('cityOfOrigin', city?.long_name || city2?.long_name);
        return getLatLng(results[0]);
      })
      .then(({ lat, lng }) => {
        setValue('addressFrom', val.label);
        setValue('startLat', lat);
        setValue('startLong', lng);
      });
  };

  const cleanAddressFrom = (setValue: any) => {
    setValue('addressFrom', null);
    setValue('startLat', null);
    setValue('startLong', null);
  };

  const handleAddressTo = (val: any, setValue: any) => {
    geocodeByAddress(val?.label)
      .then(results => {
        const city = results[0].address_components.find(item =>
          item.types.includes('administrative_area_level_2'),
        );
        const city2 = results[0].address_components.find(item =>
          item.types.includes('administrative_area_level_1'),
        );
        setValue('cityOfDestination', city?.long_name || city2?.long_name);
        return getLatLng(results[0]);
      })
      .then(({ lat, lng }) => {
        setValue('addressTo', val.label);
        setValue('finalLat', lat);
        setValue('finalLong', lng);
      });
  };

  const cleanAddressTo = (setValue: any) => {
    setValue('addressTo', '');
    setValue('finalLat', 0);
    setValue('finalLong', 0);
  };

  const handleAddStopPoint = (val: any, setValue: any, id: number) => {
    const findStopPoint = stopPoints.find(stopPoint => stopPoint.order === id);
    if (!findStopPoint) return;
    geocodeByAddress(val?.label)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        findStopPoint.address = val.label;
        findStopPoint.lat = lat;
        findStopPoint.long = lng;
        setValue((prev: any) => [...prev]);
      });
  };

  const cleanStopPoint = (setValue: any, id = 0) => {
    const filteredStopPoints = stopPoints.filter(
      stopPoint => stopPoint.order !== id,
    );

    setValue(filteredStopPoints);
  };

  useEffect(() => {
    setValue('sectorId', '');
  }, [selectedCompany, setValue]);

  const onSubmit = (values: {
    addressFrom: string;
    addressTo: string;
    startLat: number;
    startLong: number;
    finalLat: number;
    finalLong: number;
    sectorId?: string;
    vehicleCategoryId?: string;
    vehicleClassId?: string;
    cityOfDestination?: string;
    cityOfOrigin?: string;
    observation?: string;
  }) => {
    if (!addressFrom) {
      addNotification({
        title: 'Erro',
        type: 'error',
        message: 'Por favor, preencha o endereço de origem!',
      });
      return;
    }
    if (!disposition && !addressTo) {
      addNotification({
        title: 'Erro',
        type: 'error',
        message: 'Por favor, preencha o endereço de destino!',
      });
      return;
    }
    if (!selectedCompany) {
      addNotification({
        title: 'Erro',
        type: 'error',
        message: 'Por favor, selecione uma empresa!',
      });
      return;
    }
    if (!userIds[0].id) {
      addNotification({
        title: 'Erro',
        type: 'error',
        message: 'Por favor, selecione um passageiro!',
      });
      return;
    }
    if (!values.sectorId) {
      addNotification({
        title: 'Erro',
        type: 'error',
        message: 'Por favor, selecione um setor!',
      });
      return;
    }

    const findIncompleteStopPoint = stopPoints.find(
      stopPoint => !stopPoint.lat,
    );
    if (findIncompleteStopPoint) {
      addNotification({
        title: 'Erro',
        type: 'error',
        message: 'Por favor, preencha os endereços!',
      });
      return;
    }

    const tripValues = {
      addressFrom: values.addressFrom,
      addressTo: !disposition ? values.addressTo : undefined,
      startLat: values.startLat,
      startLong: values.startLong,
      finalLat: values.finalLat,
      finalLong: values.finalLong,
      sectorId: values.sectorId,
      cityOfOrigin: values.cityOfOrigin,
      cityOfDestination: values.cityOfDestination,
      scheduledDate,
      scheduledReturnDate,
      vehicleCategoryId: values.vehicleCategoryId || undefined,
      requestedVehicleClassId: values.vehicleClassId || undefined,
      stopPoints: stopPoints.map(stopPoint => ({
        ...stopPoint,
        observation: stopPointsObservation.find(
          data => data.stopPointId === stopPoint.order,
        )?.observation,
      })),
      clientTripId,
      observation,
      userIds: userIds.filter(data => data.id !== '').map(user => user.id),
      companyId: selectedCompany,
    };

    requestTrip(
      {
        ...tripValues,
      },
      {
        onSuccess: () => {
          setUserIds([
            {
              key: Date.now(),
              id: '',
            },
          ]);
          setIsScheduled(false);
          setScheduledDate(null);
        },
      },
    );
  };

  return (
    <>
      <Modal
        onClose={() =>
          setSelectFavoriteAddress({ open: false, handler: () => {} })
        }
        style={{
          backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '',
        }}
        open={selectFavoriteAddress.open}
        size="xs"
        dialogContent={
          <SelectFavoriteAddress
            onSelectAddress={selectFavoriteAddress.handler}
          />
        }
      />
      <Modal
        onClose={() => closeCreateSector()}
        style={{
          backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '',
        }}
        open={isCreateSectorOpen}
        size="xs"
        title="Adicionar setor"
        dialogContent={
          <CreateSectorAsRenter
            companyId={selectedCompany}
            onSuccess={(sectorId: string) => {
              closeCreateSector();
              setValue('sectorId', sectorId);
            }}
          />
        }
      />
      <Modal
        onClose={() => closeCreatePassenger()}
        style={{
          backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '',
        }}
        open={isCreatePassengerOpen}
        size="sm"
        title="Adicionar passageiro"
        dialogContent={
          <CreatePassengerAsRenter
            companyId={selectedCompany}
            onSuccess={() => closeCreatePassenger()}
          />
        }
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: isMobile ? '100%' : '600px',
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '20px',
                    justifyContent: 'start',
                    alignItems: 'center',
                  }}
                >
                  <AddressInput
                    clean={cleanAddressFrom}
                    handleAddress={handleAddressFrom}
                    setValue={setValue}
                    placeholder={addressFrom || 'Origem'}
                    onAddFavoriteAddress={() =>
                      handleSelectFavoriteAddressToOrigin(setValue)
                    }
                    value={addressFrom}
                    zIndex={999}
                  />
                  <Box
                    sx={{
                      width: '240px',
                      marginTop: '15px',
                      marginLeft: '40px',
                    }}
                  >
                    <TextField
                      size="small"
                      placeholder="Observação (complemento)"
                      value={observation}
                      onChange={e => setObservation(e.target.value)}
                    />
                  </Box>
                  {!disposition && (
                    <>
                      {stopPoints.length >= 1 &&
                        stopPoints
                          .sort((a, b) => a.order - b.order)
                          .map((stopPoint, i) => (
                            <Box key={stopPoint.order}>
                              <AddressInput
                                zIndex={999 - i - 1}
                                clean={cleanStopPoint}
                                handleAddress={handleAddStopPoint}
                                setValue={setStopPoints}
                                placeholder="Parada"
                                id={stopPoint.order}
                                isStopPoint
                                onAddFavoriteAddress={() =>
                                  handleSelectFavoriteAddressToStopPoint({
                                    setStopPoints,
                                    order: stopPoint.order,
                                  })
                                }
                                value={stopPoint.address}
                              />
                              <Box
                                sx={{
                                  width: '240px',
                                  marginTop: '15px',
                                  marginLeft: '40px',
                                }}
                              >
                                <TextField
                                  size="small"
                                  placeholder={`Observação parada ${i + 1} `}
                                  value={
                                    stopPointsObservation.find(
                                      ({ stopPointId }) =>
                                        stopPointId === stopPoint.order,
                                    )?.observation
                                  }
                                  onChange={e =>
                                    setStopPointsObservation(prev => {
                                      const findStopPoint = prev.find(
                                        data =>
                                          data.stopPointId === stopPoint.order,
                                      );
                                      if (findStopPoint) {
                                        findStopPoint.observation =
                                          e.target.value;
                                        return [...prev];
                                      }
                                      return [
                                        ...prev,
                                        {
                                          stopPointId: stopPoint.order,
                                          observation: e.target.value,
                                        },
                                      ];
                                    })
                                  }
                                />
                              </Box>
                            </Box>
                          ))}
                      <AddressInput
                        clean={cleanAddressTo}
                        handleAddress={handleAddressTo}
                        setValue={setValue}
                        zIndex={990}
                        placeholder={addressTo || 'Destino'}
                        onAddFavoriteAddress={() =>
                          handleSelectFavoriteAddressToDestination(setValue)
                        }
                        value={addressTo}
                      />
                    </>
                  )}
                  {!disposition && (
                    <Box sx={{ width: '100%' }}>
                      <StopPoints
                        stopPoints={stopPoints}
                        setStopPoints={setStopPoints}
                      />
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                    }}
                  >
                    <FormControlLabel
                      sx={{
                        width: '100%',
                        color: 'gray',
                      }}
                      control={
                        <Checkbox
                          onChange={() => {
                            setDisposition(prev => !prev);
                            setStopPoints([]);
                          }}
                          checked={disposition}
                        />
                      }
                      label="Motorista à disposição"
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      flexDirection: 'column',
                      gap: '10px',
                    }}
                  >
                    <FormControlLabel
                      sx={{
                        width: '100%',
                        color: 'gray',
                      }}
                      control={
                        <Checkbox
                          onChange={() => {
                            setIsScheduled(prev => !prev);
                            setScheduledDate(null);
                          }}
                          checked={isScheduled}
                        />
                      }
                      label="Agendar"
                    />
                    {isScheduled && (
                      <LocalizationProvider
                        locale={ptLocale}
                        dateAdapter={AdapterDateFns}
                      >
                        <DateTimePicker
                          label="Data inicial"
                          value={scheduledDate}
                          onChange={newValue => {
                            setScheduledDate(newValue);
                          }}
                          renderInput={params => (
                            <TextField
                              sx={{ zIndex: 0 }}
                              size="small"
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    )}
                    {!disposition && (
                      <>
                        <FormControlLabel
                          sx={{
                            width: '100%',
                            color: 'gray',
                          }}
                          control={
                            <Checkbox
                              onChange={() => {
                                setIsReturnScheduled(prev => !prev);
                                setScheduledReturnDate(null);
                              }}
                              checked={isReturnScheduled}
                            />
                          }
                          label="Agendar retorno"
                        />
                        {isReturnScheduled && (
                          <LocalizationProvider
                            locale={ptLocale}
                            dateAdapter={AdapterDateFns}
                          >
                            <DateTimePicker
                              label="Data de retorno"
                              value={scheduledReturnDate}
                              onChange={newValue => {
                                setScheduledReturnDate(newValue);
                              }}
                              renderInput={params => (
                                <TextField
                                  sx={{ zIndex: 0 }}
                                  size="small"
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      </>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      marginTop: '15px',
                    }}
                  >
                    <FormControlLabel
                      sx={{
                        width: '100%',
                        color: 'gray',
                      }}
                      control={
                        <Checkbox
                          onChange={() =>
                            setNeedSpecificCar(prev => {
                              if (prev) {
                                setValue('vehicleCategoryId', '');
                                return false;
                              }
                              return true;
                            })
                          }
                          checked={needSpecificCar}
                        />
                      }
                      label="Preciso de um tipo específico de veículo"
                    />
                  </Box>
                  {needSpecificCar && (
                    <Box
                      sx={{
                        width: '100%',
                        marginTop: '-10px',
                      }}
                    >
                      <SelectTypeOfVehicle
                        onChange={e => {
                          setValue('vehicleCategoryId', e.target.value);
                        }}
                      />
                    </Box>
                  )}
                  <Box
                    sx={{
                      width: '100%',
                      marginTop: '2px',
                    }}
                  >
                    <SelectClassOfVehicle
                      classes={classes || []}
                      onChange={e => {
                        setValue('vehicleClassId', e.target.value);
                      }}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '20px',
                    justifyContent: 'start',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      padding: '10px 20px',
                      width: isMobile ? '100%' : '300px',
                      zIndex: 1,
                      marginTop: '5px',
                    }}
                  >
                    <SelectCompany
                      companies={companies || []}
                      setCompany={setSelectedCompany}
                    />
                    {selectedCompany && (
                      <SelectSector
                        onClickAdd={() => openCreateSector()}
                        value={sectorId}
                        onChange={e => {
                          setValue('sectorId', e.target.value);
                        }}
                        selectedCompany={selectedCompany}
                      />
                    )}
                  </Box>
                  {selectedCompany && (
                    <>
                      {userIds.map(passenger => (
                        <SelectPassengerAsRenter
                          key={passenger.key}
                          companyId={selectedCompany}
                          onAdd={() => openCreatePassenger()}
                          onChange={option => {
                            const alreadySelected = userIds.find(
                              user => user.id === option?.id,
                            );
                            if (alreadySelected) {
                              addNotification({
                                title: 'Esse passageiro já foi selecionado',
                                type: 'warning',
                              });
                              return;
                            }
                            const passengerIndex = userIds.findIndex(
                              data => data.key === passenger.key,
                            );

                            setUserIds([
                              ...userIds.slice(0, passengerIndex),
                              {
                                key: passenger.key,
                                id: option?.id || '',
                              },
                              ...userIds.slice(
                                passengerIndex + 1,
                                userIds.length,
                              ),
                            ]);
                          }}
                          onClose={() => {
                            if (userIds.length === 1) {
                              addNotification({
                                title: 'É necessário ao menos um passageiro',
                                type: 'warning',
                              });
                            }
                            const filteredPassengers = userIds.filter(
                              data => data.key !== passenger.key,
                            );
                            setUserIds(filteredPassengers);
                          }}
                          userIds={userIds}
                        />
                      ))}
                      <Tooltip
                        sx={{ alignSelf: 'flex-end' }}
                        title="Adicionar passageiro"
                      >
                        <IconButton
                          onClick={() => {
                            setUserIds(prev => [
                              ...prev,
                              {
                                id: '',
                                key: Date.now(),
                              },
                            ]);
                          }}
                        >
                          <AddCircle sx={{ color: 'green' }} />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </Grid>
              </Grid>
              <Box
                sx={{
                  width: '300px',
                  marginTop: '15px',
                  marginLeft: '20px',
                }}
              >
                <TextField
                  size="small"
                  placeholder="Id do cliente (opcional)"
                  value={clientTripId}
                  onChange={e => setClientTripId(e.target.value)}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '20px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderBottomRightRadius: '30px',
                  borderBottomLeftRadius: '30px',
                }}
              >
                <LoadingButton
                  disabled={isSendingTrip}
                  loading={isSendingTrip}
                  type="submit"
                  variant="contained"
                  color="success"
                  sx={{ marginTop: '10px', borderRadius: '15px' }}
                >
                  Solicitar viagem
                </LoadingButton>
              </Box>
            </Box>
          </>
        </form>
      </Box>
    </>
  );
};
