import { Can } from '@/components/Can';
import { Route, Routes } from 'react-router-dom';
import { useAuth } from '@/providers/auth';
import { UpdateUser } from '../components/UpdateUser';
import { CreateUser } from './CreateUser';
import { UsersList } from './UsersList';

export const UsersRoutes = (): JSX.Element => {
  const { user } = useAuth();
  const hasPermissionToViewSensitiveData = user?.user?.profile?.find(
    data =>
      data.name === 'editar passageiros e visualizar informações sensíveis',
  );
  return (
    <Can permissions={['sector_admin', 'company_admin']}>
      <Routes>
        <Route path="/" element={<UsersList />} />
        {hasPermissionToViewSensitiveData && (
          <Route path="/:userId" element={<UpdateUser />} />
        )}
        <Route path="/create" element={<CreateUser />} />
      </Routes>
    </Can>
  );
};
