import { axios } from '@/lib/axios';

import { useQuery } from 'react-query';

interface IRequest {
  companyId: string;
  startDate: string | Date;
  endDate: string | Date;
}

export interface IOpexCapexTripData {
  label: string;
  data: number[];
  backgroundColor: string;
}

interface IOutput {
  totalByHour: IOpexCapexTripData[];
  capexByHour: IOpexCapexTripData[];
  opexByHour: IOpexCapexTripData[];
  totalAmout: number;
  capexAmount: number;
  opexAmount: number;
}

export async function getOpexCapexReport({
  endDate,
  startDate,
  companyId,
}: IRequest): Promise<IOutput> {
  const response = await axios.get(
    `reports/getCapexOpexReport/${companyId}?startDate=${startDate}&endDate=${endDate}`,
  );

  return response.data.payload;
}

export const useGetOpexCapexReport = ({
  endDate,
  startDate,
  companyId,
}: IRequest) => {
  return useQuery({
    queryKey: ['opexCapex', endDate, startDate, companyId],
    queryFn: () => getOpexCapexReport({ endDate, startDate, companyId }),
  });
};
