/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import {
  Box,
  IconButton,
  Snackbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ConfirmModal, Modal, Table } from '@/components';

import {
  CallMissedOutgoing,
  Check,
  Delete,
  DeleteSharp,
  Place,
  Star,
} from '@mui/icons-material';

import { useDisclosure } from '@/hooks/useDisclosure';

import { BiPencil } from 'react-icons/bi';
import { loadOpenTripsResponse } from '../../types';
import { useCancelTrip, useValidateTrip } from '../../api';
import { EditTrip } from './EditTrip';
import { ConfirmCancelation } from './ConfirmCancelation';

export const CompanyTripsGrid = ({
  openTripsData,
  setTripToAvaliate,
}: {
  openTripsData: loadOpenTripsResponse[];
  setTripToAvaliate: (trip: string) => void;
}) => {
  const { isOpen, toggle, close } = useDisclosure();
  const {
    isOpen: isRefuseValidationOpen,
    toggle: toggleRefuseValidation,
    close: closeRefuseValidation,
  } = useDisclosure();
  const [selectedId, setSelectedId] = useState('');
  const { mutateAsync: cancelTrip, isLoading: isCanceling } = useCancelTrip();
  const [showCopyToast, setShowCopyToast] = useState(false);
  const [selectedTripToEdit, setSelectedTripToEdit] = useState<{
    scheduledDate: string;
    id: string;
  } | null>(null);

  const { mutateAsync: validateTrip, isLoading: isValidating } =
    useValidateTrip();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const generateLinkToViewTrip = (tripId: string) => {
    const url = `${window.location.origin}/tripVisualization/${tripId}`;
    navigator.clipboard.writeText(url).then(() => {
      setShowCopyToast(true);
      setTimeout(() => {
        setShowCopyToast(false);
      }, 2000);
    });
  };

  const handleDelete = () => {
    cancelTrip(
      {
        id: selectedId,
      },
      { onSuccess: () => close() },
    );
  };

  return (
    <>
      {selectedTripToEdit && (
        <Modal
          open={!!selectedTripToEdit}
          onClose={() => setSelectedTripToEdit(null)}
          size="xs"
          title="Modificar agendamento"
          dialogContent={
            <EditTrip
              scheduledDate={selectedTripToEdit.scheduledDate}
              tripId={selectedTripToEdit.id}
              onSuccess={() => setSelectedTripToEdit(null)}
            />
          }
        />
      )}
      <Table<loadOpenTripsResponse>
        data={openTripsData || []}
        rowsPerPage={isMobile ? 5 : 100}
        width="150px"
        fontSize={13}
        columns={[
          { title: 'Id sistema', field: 'id', colSpan: 1 },
          { title: 'Motorista', field: 'driverName', colSpan: 1 },
          { title: 'Id opcional', field: 'clientTripId', colSpan: 1 },
          { title: 'Placa', field: 'licensePlate', colSpan: 1 },
          { title: 'Solicitante', field: 'requestedByName', colSpan: 1 },
          {
            title: 'Passageiro(s)',
            field: 'users',
            colSpan: 1,
            Cell({ entry: { users } }) {
              if (!users) return <></>;
              return (
                <Box
                  sx={{
                    ':hover': {
                      '.users': {
                        display: 'block',
                      },
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.mode === 'dark' ? '#FFF' : '#000',
                      fontSize: 13,
                    }}
                    className="users"
                  >
                    {users
                      .sort((a, b) => (a?.order ?? 0) - (b?.order ?? 0))
                      .map(user => user.name)
                      .join(', ')}
                  </Typography>
                </Box>
              );
            },
          },
          {
            title: 'Origem',
            field: 'cityOfOrigin',
            colSpan: 1,
            color: true,
            Cell({ entry: { cityOfOrigin, addressFrom } }) {
              return (
                <Box
                  sx={{
                    ':hover': {
                      '.cityOfOrigin': {
                        display: 'none',
                      },
                      '.addressFrom': {
                        display: 'block',
                      },
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.mode === 'dark' ? '#FFF' : '#000',
                      fontSize: 13,
                    }}
                    className="cityOfOrigin"
                  >
                    {cityOfOrigin}
                  </Typography>
                  <Typography
                    sx={{
                      display: 'none',
                      color: theme.palette.mode === 'dark' ? '#FFF' : '#000',
                      fontSize: 13,
                    }}
                    className="addressFrom"
                  >
                    {addressFrom}
                  </Typography>
                </Box>
              );
            },
          },
          {
            title: 'Paradas',
            field: 'stopPoints',
            colSpan: 1,
            color: true,
            Cell({ entry: { stopPoints } }) {
              return (
                <Box
                  sx={{
                    ':hover': {
                      '.stopPoints': {
                        display: 'none',
                      },
                      '.stopPointsAdresses': {
                        display: 'flex',
                      },
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.mode === 'dark' ? '#FFF' : '#000',
                      fontSize: 13,
                    }}
                    className={stopPoints?.length > 0 ? 'stopPoints' : ''}
                  >
                    {stopPoints?.length > 0
                      ? stopPoints.length
                      : 'Sem pontos de paradas'}
                  </Typography>
                  {stopPoints
                    ?.sort((pointA, pointB) => pointA.order - pointB.order)
                    ?.map((stopPoint, index) => (
                      <Box
                        className="stopPointsAdresses"
                        key={index}
                        sx={{
                          display: 'none',
                          alignItems: 'center',
                          gap: '5px',
                          marginTop: '5px',
                        }}
                      >
                        <Place fontSize="small" />
                        <Typography
                          sx={{
                            color:
                              theme.palette.mode === 'dark' ? '#FFF' : '#000',
                            fontSize: '12px',
                          }}
                        >
                          {stopPoint.address}
                        </Typography>
                      </Box>
                    ))}
                </Box>
              );
            },
          },
          {
            title: 'Destino',
            field: 'cityOfDestination',
            colSpan: 1,
            color: true,
            Cell({ entry: { cityOfDestination, addressTo } }) {
              return (
                <Box
                  sx={{
                    ':hover': {
                      '.cityOfDestination': {
                        display: 'none',
                      },
                      '.addressTo': {
                        display: 'block',
                      },
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.mode === 'dark' ? '#FFF' : '#000',
                      fontSize: 13,
                    }}
                    className="cityOfDestination"
                  >
                    {cityOfDestination}
                  </Typography>
                  <Typography
                    sx={{
                      display: 'none',
                      color: theme.palette.mode === 'dark' ? '#FFF' : '#000',
                      fontSize: 13,
                    }}
                    className="addressTo"
                  >
                    {addressTo}
                  </Typography>
                </Box>
              );
            },
          },
          {
            title: 'Data da solicitação',
            field: 'requestDate',
            colSpan: 2,
          },
          {
            title: 'Data do agendamento',
            field: 'scheduledDate',
            colSpan: 2,
            Cell({ entry: { scheduledDate, id } }) {
              return (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '5px',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography sx={{ fontSize: 13 }}>
                      {scheduledDate}
                    </Typography>
                    {scheduledDate && (
                      <Tooltip title="Editar">
                        <IconButton
                          onClick={() =>
                            setSelectedTripToEdit({
                              scheduledDate,
                              id,
                            })
                          }
                        >
                          <BiPencil size={18} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                </>
              );
            },
          },
          {
            title: 'Tipo de corrida',
            field: 'type',
            colSpan: 1,
            color: true,
            Cell({ entry: { color, type, background } }) {
              return (
                <>
                  <Box
                    sx={{
                      background,
                      padding: '10px',
                      borderRadius: '20px',
                    }}
                  >
                    <Typography
                      sx={{ color, fontWeight: 'bold', fontSize: 13 }}
                    >
                      {type}
                    </Typography>
                  </Box>
                </>
              );
            },
          },
          {
            title: 'Status',
            field: 'status',
            colSpan: 1,
            color: true,
            Cell({ entry: { status } }) {
              const statusText: any = {
                requested: 'Aguardando resposta',
                pending: 'Aguardando motorista',
                scheduled: 'Agendado',
                accepted: 'A caminho',
                started: 'Iniciada',
                finished: 'Finalizada',
                requestRejected: 'Rejeitada',
                requestCanceled: 'Cancelada',
                waitingValidation: 'Aguardando validação',
              };
              const pallete: any = {
                requested: {
                  background: '#FFDFDF',
                  color: '#CE4F4F',
                },
                pending: {
                  background: '#FFEEDB',
                  color: '#BA7C38',
                },
                scheduled: {
                  background: '#E9F4F5',
                  color: '#316065',
                },
                accepted: {
                  background: '#DDDDFF',
                  color: '#4542DB',
                },
                started: {
                  background: '#CFEEFF',
                  color: '#136490',
                },
                finished: {
                  background: '#CFFFCB',
                  color: '#17880D',
                },
                requestRejected: {
                  background: '#FFDFDF',
                  color: '#CE4F4F',
                },
                requestCanceled: {
                  background: '#DDDDDD',
                  color: '#4E4E4E',
                },
              };
              return (
                <>
                  <Box
                    sx={{
                      background: pallete[status]?.background || '#FFDFDF',
                      width: '200px',
                      padding: '5px',
                      borderRadius: '20px',
                    }}
                  >
                    <Typography
                      sx={{
                        color: pallete[status]?.color || '#CE4F4F',
                        fontWeight: 'bold',
                        fontSize: 13,
                      }}
                    >
                      {statusText[status]}
                    </Typography>
                  </Box>
                </>
              );
            },
          },
          {
            title: '',
            field: 'id',
            colSpan: 1,
            Cell({ entry: { id, status, userTripAnswer, driverId } }) {
              return (
                <Box
                  sx={{
                    display: 'flex',
                    gap: '5px',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  {(status === 'accepted' ||
                    status === 'started' ||
                    status === 'finished') &&
                    driverId && (
                      <Tooltip
                        title="Link para acompanhar em tempo real"
                        sx={{ padding: '0px' }}
                      >
                        <IconButton onClick={() => generateLinkToViewTrip(id)}>
                          <CallMissedOutgoing
                            sx={{
                              fontSize: 20,
                              color:
                                theme.palette.mode === 'dark'
                                  ? '#FFF'
                                  : '#1d1d1d',
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                  <Box>
                    {(status === 'requested' ||
                      status === 'pending' ||
                      status === 'scheduled') && (
                      <Tooltip sx={{ padding: '0px' }} title="Deletar">
                        <IconButton
                          disabled={isValidating}
                          onClick={() => {
                            toggle();
                            setSelectedId(id);
                          }}
                        >
                          <DeleteSharp sx={{ color: 'red', fontSize: 20 }} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                  <Box>
                    {status === 'waitingValidation' && (
                      <Box sx={{ display: 'flex', gap: '5px' }}>
                        <Tooltip
                          sx={{ padding: '0px' }}
                          title="Validar corrida"
                        >
                          <IconButton
                            disabled={isValidating}
                            onClick={() =>
                              validateTrip({
                                id,
                                isValidated: true,
                              })
                            }
                          >
                            <Check sx={{ fontSize: 20 }} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          sx={{ padding: '0px' }}
                          title="Recusar validação"
                        >
                          <IconButton
                            disabled={isCanceling}
                            onClick={() => {
                              setSelectedId(id);
                              toggleRefuseValidation();
                            }}
                          >
                            <Delete sx={{ color: 'red', fontSize: 20 }} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                  </Box>
                  <Box>
                    {status === 'finished' &&
                      !userTripAnswer?.driverAvaliation && (
                        <Tooltip
                          sx={{ padding: '0px' }}
                          title="Avaliar corrida"
                        >
                          <IconButton
                            disabled={isCanceling}
                            onClick={() => setTripToAvaliate(id)}
                          >
                            <Star sx={{ color: 'red', fontSize: 20 }} />
                          </IconButton>
                        </Tooltip>
                      )}
                  </Box>
                </Box>
              );
            },
          },
        ]}
      />
      {isRefuseValidationOpen && (
        <Modal
          title="Recusar validação"
          dialogContent={
            <>
              <ConfirmModal
                isOpen={isRefuseValidationOpen}
                isLoading={isValidating}
                title="Tem certeza que deseja recusar a validação?"
                close={closeRefuseValidation}
                handleConfirmation={() => {
                  validateTrip({
                    id: selectedId,
                    isValidated: false,
                  });
                }}
              />
            </>
          }
          open={isRefuseValidationOpen}
          size="sm"
          onClose={closeRefuseValidation}
        />
      )}
      {isOpen && (
        <Modal
          title="Cancelar corrida"
          dialogContent={
            <>
              <ConfirmCancelation closeModal={close} id={selectedId} />
            </>
          }
          open={isOpen}
          size="sm"
          onClose={close}
        />
      )}
      <Snackbar
        open={showCopyToast}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => setShowCopyToast(false)}
        message="Copiado para área de transferência"
      />
    </>
  );
};
