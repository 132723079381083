import {
  createTheme,
  CssBaseline,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material';
import { storage } from '@/utils/storage';
import { ptBR } from '@mui/material/locale';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Logo from '@/assets/img/LogoAuth.png';
import OneLogo from '@/assets/img/OneLogo.png';
import LogoWhite from '@/assets/img/LogoAuth2.png';
import backgroundWeb from '@/assets/img/login-web.png';
import jslBackgroud from '@/assets/img/jsl-background-image.png';
import onecarLogo from '@/assets/img/onecar-logo.png';
import topDeLinhaLogo from '@/assets/img/top_de_linha_logo.png';
import topDeLinhaIcon from '@/assets/img/top_de_linha_icon.png';
import topDeLinhaWhiteIcon from '@/assets/img/top_de_linha_white_icon.png';
import jslAuthLogo from '@/assets/img/jsl-auth-logo.png';
import jslBackgroundLogo from '@/assets/img/jsl-background-logo.png';
import sidebarLogoPng from '@/assets/img/OneLogo-white.png';
import favIcon from '@/assets/img/favIcon.png';

interface ThemeProviderProps {
  children: ReactNode;
}

interface ThemeContextData {
  toggleColorMode: () => void;
  sidebarLogo: string;
  sidebarColor: string;
  sideBarIconBackgroundColor: string;
  authBackgroundColor: string;
  authBackgroundImage: string;
  subTitle: 'One car' | 'JSL Executive' | 'Top de Linha';
  authBackgroundLogo: string;
  authLogo: string;
  homePageLogo: string;
  selectedDomain: string;
  defaultPrimaryColor: string;
}

const ThemeContext = createContext({} as ThemeContextData);

const ThemeProvider = ({ children }: ThemeProviderProps): JSX.Element => {
  const getMode = storage.getItem({ key: 'theme', storageType: 'session' });

  const [mode, setMode] = useState<'light' | 'dark'>(getMode?.mode || 'light');

  const toggleColorMode = useCallback(() => {
    setMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'));
  }, []);

  useEffect(() => {
    storage.setItem({
      key: 'theme',
      storageType: 'session',
      values: { mode },
    });
  }, [mode]);

  const theme = useMemo(
    () =>
      createTheme(
        {
          palette: {
            mode,
            ...(mode === 'dark' && {
              background: {
                default: '#212121',
                paper: '#212121',
              },
            }),
          },
          typography: {
            fontFamily: ['Inter'].join(','),
          },
        },
        ptBR,
      ),
    [mode],
  );

  const subDomain = window.location.hostname.split('.')[0];

  const domains: any = {
    onecar: 'DEFAULT',
    localhost: 'DEFAULT',
    jslexecutive: 'JSL',
    cloudfront: 'JSL',
    topdelinha: 'TOPDELINHA',
  };
  const selectedDomain = domains[subDomain] || 'DEFAULT';
  const themeMode = theme.palette.mode.toLocaleUpperCase() as 'LIGHT' | 'DARK';

  const sidebarLogoOptions: any = {
    DEFAULT: sidebarLogoPng,
    JSL: jslBackgroundLogo,
    TOPDELINHA: topDeLinhaWhiteIcon,
  };

  const sidebarLogo = sidebarLogoOptions[selectedDomain];

  const sidebarColorOptions: any = {
    DEFAULT: {
      LIGHT: 'linear-gradient(180deg, #01E6E0 -35.19%, #0C76D3 64.8%)',
      DARK: 'linear-gradient(180deg, #064379 0%, #064379 100%)',
    },
    TOPDELINHA: {
      LIGHT: 'linear-gradient(180deg, #01E6E0 -35.19%, #0C76D3 64.8%)',
      DARK: 'linear-gradient(180deg, #3E3E3E 0%, #424242 100%)',
    },
    JSL: {
      LIGHT: 'linear-gradient(180deg, #F4484A 0%, #C82426 100%)',
      DARK: 'linear-gradient(180deg, #3E3E3E 0%, #424242 100%)',
    },
  };

  const defaultPrimaryColorOptions: any = {
    DEFAULT: {
      LIGHT: '#1976D2',
      DARK: '#064379',
    },
    TOPDELINHA: {
      LIGHT: '#1976D2',
      DARK: '#3E3E3E',
    },
    JSL: {
      LIGHT: '#F4484A)',
      DARK: '#3E3E3E',
    },
  };

  const sidebarColor = sidebarColorOptions[selectedDomain][themeMode];

  const defaultPrimaryColor =
    defaultPrimaryColorOptions[selectedDomain][themeMode];

  const sideBarIconBackgroundColorOptions: any = {
    DEFAULT: {
      LIGHT: '#1892B9',
      DARK: 'white',
    },
    TOPDELINHA: {
      LIGHT: '#1892B9',
      DARK: 'white',
    },
    JSL: {
      LIGHT: 'red',
      DARK: 'white',
    },
  };

  const sideBarIconBackgroundColor =
    sideBarIconBackgroundColorOptions[selectedDomain][themeMode];

  const authBackgroundColorOptions: any = {
    DEFAULT: '#EDE530',
    TOPDELINHA: '#EDE530',
    JSL: '#747476',
  };

  const authBackgroundColor = authBackgroundColorOptions[selectedDomain];

  const authBackgroundImageOptions: any = {
    DEFAULT: backgroundWeb,
    TOPDELINHA: backgroundWeb,
    JSL: jslBackgroud,
  };

  const authBackgroundImage = authBackgroundImageOptions[selectedDomain];

  const authBackgroundLogoOptions: any = {
    DEFAULT: onecarLogo,
    TOPDELINHA: '',
    JSL: jslBackgroundLogo,
  };

  const authBackgroundLogo = authBackgroundLogoOptions[selectedDomain];

  const authLogoOptions: any = {
    DEFAULT: {
      LIGHT: Logo,
      DARK: LogoWhite,
    },
    TOPDELINHA: {
      LIGHT: topDeLinhaLogo,
      DARK: topDeLinhaLogo,
    },
    JSL: {
      LIGHT: jslAuthLogo,
      DARK: jslAuthLogo,
    },
  };

  const authLogo = authLogoOptions[selectedDomain][themeMode];

  const homePageLogoOptions: any = {
    DEFAULT: {
      LIGHT: OneLogo,
      DARK: OneLogo,
    },
    TOPDELINHA: {
      LIGHT: topDeLinhaIcon,
      DARK: topDeLinhaIcon,
    },
    JSL: {
      LIGHT: jslAuthLogo,
      DARK: jslAuthLogo,
    },
  };

  const homePageLogo = homePageLogoOptions[selectedDomain][themeMode];

  const subTitleOptions: any = {
    DEFAULT: 'One Car',
    TOPDELINHA: 'Top de Linha',
    JSL: 'JSL Executive',
  };

  const subTitle: 'One car' | 'JSL Executive' | 'Top de Linha' =
    subTitleOptions[selectedDomain];

  useEffect(() => {
    const possibleTitles: any = {
      DEFAULT: 'OneCar',
      JSL: 'JSL',
      TOPDELINHA: 'Top de Linha',
    };

    const possiblesFavicons: any = {
      DEFAULT: favIcon,
      TOPDELINHA: topDeLinhaIcon,
      JSL: jslAuthLogo,
    };
    const favicon = possiblesFavicons[selectedDomain];

    document.title = possibleTitles[selectedDomain];

    const faviconLink = document.querySelector(
      "link[rel='icon']",
    ) as HTMLLinkElement;
    if (faviconLink) {
      faviconLink.href = favicon;
    }
  }, [selectedDomain]);

  return (
    <ThemeContext.Provider
      value={{
        toggleColorMode,
        sidebarLogo,
        defaultPrimaryColor,
        sidebarColor,
        sideBarIconBackgroundColor,
        authBackgroundColor,
        authBackgroundImage,
        authBackgroundLogo,
        subTitle,
        authLogo,
        homePageLogo,
        selectedDomain,
      }}
    >
      <CssBaseline />
      <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
    </ThemeContext.Provider>
  );
};

function useCustomTheme(): ThemeContextData {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error('useCustomTheme must be used with a ThemeProvider.');
  }

  return context;
}

export { ThemeProvider, useCustomTheme };
