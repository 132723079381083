import { axios } from '@/lib/axios';

import { useQuery } from 'react-query';
import { listClosedTripsResponse } from '../types';

interface GetAllTripsProps {
  companyId?: string;
  driverId: string | null;
  sector: string | null;
  onlyStartedTrips: boolean;
  initialDate: Date;
  finalDate: Date;
}

export async function getAllTripsReports(
  data: GetAllTripsProps,
): Promise<listClosedTripsResponse[] | any> {
  console.log('getAllTripsReports', typeof data.onlyStartedTrips);
  const response = await axios.get('reports/listAllTrips', { params: data });

  return response.data.payload;
}

export const useAllTripsReports = ({
  driverId,
  initialDate,
  finalDate,
  companyId,
  sector,
  onlyStartedTrips,
}: GetAllTripsProps) => {
  return useQuery({
    queryKey: ['list_all_trips'],
    queryFn: () =>
      getAllTripsReports({
        driverId,
        initialDate,
        finalDate,
        companyId,
        sector,
        onlyStartedTrips,
      }),
    enabled: false,
    initialData: [],
  });
};
