/* eslint-disable import/no-duplicates */
import { Modal } from '@/components';
import ptLocale from 'date-fns/locale/pt';
import { DateTimePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';

import * as z from 'zod';
import { useState } from 'react';
import { useNotificationStore } from '@/stores';
import { AddCircle } from '@mui/icons-material';
import { useAuth } from '@/providers/auth';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useHasPermission } from '@/hooks/useHasPermission';
import { useCompanies } from '@/features/companyNew';
import { SelectAutoComplete } from '@/components/SelectAutoComplete';
import { parse } from 'date-fns';
import { RequestTripConfirmation } from '../RequestTripConfirmation';
import { SelectFavoriteAddress } from '../SelectFavoriteAdresses';
import { IAddressDTO } from '../../types';
import { AddressInput } from '../AddressInput/AddressInput';
import { CreateSectorModal } from '../TripRequest/CreateSectorModal';
import { StopPoints } from '../TripRequest/StopPoints';
import { SelectTypeOfVehicle } from '../TripRequest/SelectTypeOfVehicle';
import { useUpdateTrip } from '../../api/updateTrip';
import { SelectPassengerAsRenter } from '../RequestTripAsRenter/SelectPassenger';
import { SelectSector } from '../RequestTripAsRenter/SelectSector';
import { useCheckPriceAsRenter } from '../../api';
import { CreatePassengerAsRenter } from '../RequestTripAsRenter/CreatePassengerAsRenter';

export interface IStopPoint {
  order: number;
  lat: number;
  long: number;
  address: string;
  observation?: string;
}

interface IRequest {
  id: string;
  companyId: string;
  addressFrom: string;
  scheduledDate?: string;
  addressTo: string;
  startLat: number;
  startLong: number;
  finalLat: number;
  finalLong: number;
  stopPoints: IStopPoint[];
  sectorId: string;
  vehicleCategoryId?: string;
  cityOfDestination?: string;
  users: string[];
  cityOfOrigin?: string;
  observation?: string;
  onSuccess: () => void;
}

const schema = z.object({
  addressFrom: z.string(),
  addressTo: z.string().nullable().optional(),
  cityOfOrigin: z.string().optional(),
  cityOfDestination: z.string().optional(),
  startLat: z.number(),
  startLong: z.number(),
  finalLat: z.number().nullable().optional(),
  finalLong: z.number().nullable().optional(),
  scheduledDate: z.string().optional(),
  sectorId: z.string().optional(),
  vehicleCategoryId: z.string().optional(),
  observation: z.string().optional(),
});

export const UpdateTripAsRenter = ({
  addressFrom: defaultAddressFrom,
  addressTo: defaultAddressTo,
  finalLat,
  finalLong,
  startLat,
  startLong,
  cityOfDestination,
  cityOfOrigin,
  observation: defaultObservation,
  sectorId: defaultSectorId,
  vehicleCategoryId,
  scheduledDate: defaultScheduledDate,
  stopPoints: defaultStopPoints,
  users,
  id,
  companyId,
  onSuccess,
}: IRequest) => {
  const formattedDate = defaultScheduledDate
    ? parse(defaultScheduledDate, 'dd/MM/yyyy HH:mm', new Date())
    : null;
  const [observation, setObservation] = useState(defaultObservation || '');
  const { close, isOpen, open } = useDisclosure();
  const check = useHasPermission('preço estimado');
  const { user } = useAuth();
  const { data: companies } = useCompanies();
  const { addNotification } = useNotificationStore();
  const [selectedCompany, setSelectedCompany] = useState(companyId);
  const [isScheduled, setIsScheduled] = useState(!!defaultScheduledDate);
  const [scheduledDate, setScheduledDate] = useState<Date | null>(
    formattedDate || null,
  );
  const [stopPoints, setStopPoints] = useState<IStopPoint[]>(
    defaultStopPoints || [],
  );
  interface IStopPointsObservation {
    stopPointId: number;
    observation?: string;
  }

  const [stopPointsObservation, setStopPointsObservation] = useState<
    IStopPointsObservation[]
  >(
    defaultStopPoints.map(data => {
      return { stopPointId: data.order, observation: data.observation || '' };
    }) || [],
  );
  const [disposition, setDisposition] = useState(!defaultAddressTo);
  const [needSpecificCar, setNeedSpecificCar] = useState(false);
  const [userIds, setUserIds] = useState<{ key: number; id: string }[]>(
    users.length
      ? users.map((user, i) => ({
          key: Date.now() + i,
          id: user,
        }))
      : [
          {
            key: Date.now(),
            id: '',
          },
        ],
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { mutateAsync: updateTrip, isLoading: isUpdatingTrip } =
    useUpdateTrip();
  const {
    mutateAsync: checkPrice,
    isLoading: isChecking,
    data: estimatedTripData,
  } = useCheckPriceAsRenter();
  const {
    isOpen: isCreateSectorOpen,
    open: openCreateSector,
    close: closeCreateSector,
  } = useDisclosure();
  const {
    isOpen: isCreatePassengerOpen,
    open: openCreatePassenger,
    close: closeCreatePassenger,
  } = useDisclosure();

  const { handleSubmit, setValue, getValues, watch } = useForm({
    resolver: schema && zodResolver(schema),
    defaultValues: {
      addressFrom: defaultAddressFrom,
      addressTo: defaultAddressTo,
      finalLat: finalLat || undefined,
      finalLong: finalLong || undefined,
      startLat,
      startLong,
      cityOfDestination,
      cityOfOrigin,
      observation: defaultObservation,
      sectorId: defaultSectorId,
      vehicleCategoryId,
      scheduledDate: defaultScheduledDate,
    },
  });

  const addressFrom = watch('addressFrom');
  const addressTo = watch('addressTo');
  const sectorId = watch('sectorId');

  const [selectFavoriteAddress, setSelectFavoriteAddress] = useState({
    open: false,
    handler: (data: IAddressDTO) => {},
  });

  const handleSelectFavoriteAddressToOrigin = (setValue: any) => {
    setSelectFavoriteAddress({
      open: true,
      handler: (address: IAddressDTO) => {
        setValue('addressFrom', address.address);
        setValue('cityOfOrigin', address.city);
        setValue('startLat', address.latitude);
        setValue('startLong', address.longitude);
        setSelectFavoriteAddress({
          open: false,
          handler: (data: IAddressDTO) => {},
        });
      },
    });
  };

  const handleSelectFavoriteAddressToStopPoint = ({
    setStopPoints,
    order,
  }: {
    setStopPoints: React.Dispatch<React.SetStateAction<IStopPoint[]>>;
    order: number;
  }) => {
    setSelectFavoriteAddress({
      open: true,
      handler: (address: IAddressDTO) => {
        const findStopPoint = stopPoints.find(
          stopPoint => stopPoint.order === order,
        );
        if (!findStopPoint) return;
        findStopPoint.address = address.address;
        findStopPoint.lat = address.latitude;
        findStopPoint.long = address.longitude;
        setStopPoints((prev: IStopPoint[]) => [...prev]);
        setSelectFavoriteAddress({
          open: false,
          handler: (data: IAddressDTO) => {},
        });
      },
    });
  };

  const handleSelectFavoriteAddressToDestination = (setValue: any) => {
    setSelectFavoriteAddress({
      open: true,
      handler: (address: IAddressDTO) => {
        setValue('addressTo', address.address);
        setValue('cityOfDestination', address.city);
        setValue('finalLat', address.latitude);
        setValue('finalLong', address.longitude);
        setSelectFavoriteAddress({
          open: false,
          handler: (data: IAddressDTO) => {},
        });
      },
    });
  };

  const handleAddressFrom = (val: any, setValue: any) => {
    geocodeByAddress(val?.label)
      .then(results => {
        const city = results[0].address_components.find(item =>
          item.types.includes('administrative_area_level_2'),
        );
        const city2 = results[0].address_components.find(item =>
          item.types.includes('administrative_area_level_1'),
        );
        setValue('cityOfOrigin', city?.long_name || city2?.long_name);
        return getLatLng(results[0]);
      })
      .then(({ lat, lng }) => {
        setValue('addressFrom', val.label);
        setValue('startLat', lat);
        setValue('startLong', lng);
      });
  };

  const cleanAddressFrom = (setValue: any) => {
    setValue('addressFrom', null);
    setValue('startLat', null);
    setValue('startLong', null);
  };

  const handleAddressTo = (val: any, setValue: any) => {
    geocodeByAddress(val?.label)
      .then(results => {
        const city = results[0].address_components.find(item =>
          item.types.includes('administrative_area_level_2'),
        );
        const city2 = results[0].address_components.find(item =>
          item.types.includes('administrative_area_level_1'),
        );
        setValue('cityOfDestination', city?.long_name || city2?.long_name);
        return getLatLng(results[0]);
      })
      .then(({ lat, lng }) => {
        setValue('addressTo', val.label);
        setValue('finalLat', lat);
        setValue('finalLong', lng);
      });
  };

  const cleanAddressTo = (setValue: any) => {
    setValue('addressTo', '');
    setValue('finalLat', 0);
    setValue('finalLong', 0);
  };

  const handleAddStopPoint = (val: any, setValue: any, id: number) => {
    const findStopPoint = stopPoints.find(stopPoint => stopPoint.order === id);
    if (!findStopPoint) return;
    geocodeByAddress(val?.label)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        findStopPoint.address = val.label;
        findStopPoint.lat = lat;
        findStopPoint.long = lng;
        setValue((prev: any) => [...prev]);
      });
  };

  const cleanStopPoint = (setValue: any, id = 0) => {
    const filteredStopPoints = stopPoints.filter(
      stopPoint => stopPoint.order !== id,
    );

    setValue(filteredStopPoints);
  };

  const onSubmit = (values: {
    addressFrom: string;
    addressTo: string;
    startLat: number;
    startLong: number;
    finalLat: number;
    finalLong: number;
    sectorId?: string;
    vehicleCategoryId?: string;
    cityOfDestination?: string;
    cityOfOrigin?: string;
    observation?: string;
  }) => {
    if (!disposition && !addressTo) {
      addNotification({
        title: 'Erro',
        type: 'error',
        message: 'Por favor, selecione um destino ou marque como disposição!',
      });
      return;
    }
    if (!userIds[0].id) {
      addNotification({
        title: 'Erro',
        type: 'error',
        message: 'Por favor, selecione um passageiro!',
      });
      return;
    }
    if (user.user.accessType === 'company_admin' && !values.sectorId) {
      addNotification({
        title: 'Erro',
        type: 'error',
        message: 'Por favor, selecione um setor!',
      });
      return;
    }

    const findIncompleteStopPoint = stopPoints.find(
      stopPoint => !stopPoint.lat,
    );
    if (findIncompleteStopPoint) {
      addNotification({
        title: 'Erro',
        type: 'error',
        message: 'Por favor, preencha os endereços!',
      });
      return;
    }

    const tripValues = {
      id,
      companyId: selectedCompany,
      addressFrom: values.addressFrom,
      addressTo: !disposition ? values.addressTo : undefined,
      startLat: values.startLat,
      startLong: values.startLong,
      finalLat: values.finalLat,
      finalLong: values.finalLong,
      sectorId: values.sectorId,
      cityOfOrigin: values.cityOfOrigin,
      cityOfDestination: values.cityOfDestination,
      scheduledDate,
      estimatedDistance: estimatedTripData?.estimatedDistance || 0,
      estimatedTime: estimatedTripData?.estimatedTime || 0,
      vehicleCategoryId: values.vehicleCategoryId,
      stopPoints: stopPoints.map(stopPoint => ({
        ...stopPoint,
        observation: stopPointsObservation.find(
          data => data.stopPointId === stopPoint.order,
        )?.observation,
      })),
      observation,
      userIds: userIds.filter(data => data.id !== '').map(user => user.id),
    };

    if (!check || disposition) {
      open();
    } else {
      checkPrice(
        {
          ...tripValues,
          addressTo: values.addressTo,
        },
        {
          onSuccess: () => {
            open();
          },
        },
      );
    }
  };

  return (
    <>
      <Modal
        onClose={() =>
          setSelectFavoriteAddress({ open: false, handler: () => {} })
        }
        style={{
          backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '',
        }}
        open={selectFavoriteAddress.open}
        size="xs"
        dialogContent={
          <SelectFavoriteAddress
            onSelectAddress={selectFavoriteAddress.handler}
          />
        }
      />
      <Modal
        onClose={() => closeCreateSector()}
        style={{
          backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '',
        }}
        open={isCreateSectorOpen}
        size="xs"
        title=""
        dialogContent={
          <CreateSectorModal
            onSuccess={id => {
              setValue('sectorId', id);
              closeCreateSector();
            }}
          />
        }
      />
      <Modal
        onClose={() => closeCreatePassenger()}
        style={{
          backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '',
        }}
        open={isCreatePassengerOpen}
        size="sm"
        title=""
        dialogContent={
          <CreatePassengerAsRenter
            companyId={selectedCompany}
            onSuccess={() => closeCreatePassenger()}
          />
        }
      />
      <Modal
        onClose={close}
        open={isOpen}
        size="xs"
        dialogContent={
          <RequestTripConfirmation
            onClose={() => close()}
            schuduledDate={scheduledDate}
            isLoading={isUpdatingTrip}
            onConfirm={async () => {
              const addressFrom = getValues('addressFrom');
              const addressTo = getValues('addressTo');
              const startLat = getValues('startLat');
              const startLong = getValues('startLong');
              const finalLat = getValues('finalLat');
              const finalLong = getValues('finalLong');
              const sectorId = getValues('sectorId');
              const vehicleCategoryId = getValues('vehicleCategoryId');
              const cityOfOrigin = getValues('cityOfOrigin');
              const cityOfDestination = getValues('cityOfDestination');

              updateTrip(
                {
                  id,
                  companyId: user.user.companyId,
                  observation,
                  addressFrom,
                  addressTo,
                  finalLat,
                  finalLong,
                  startLat,
                  startLong,
                  sectorId,
                  vehicleCategoryId,
                  scheduledDate,
                  cityOfOrigin,
                  cityOfDestination,
                  estimatedDistance: estimatedTripData?.estimatedDistance || 0,
                  estimatedTime: estimatedTripData?.estimatedTime || 0,
                  stopPoints: stopPoints.map(stopPoint => ({
                    ...stopPoint,
                    observation: stopPointsObservation.find(
                      data => data.stopPointId === stopPoint.order,
                    )?.observation,
                  })),
                  userIds: userIds
                    .filter(data => data.id !== '')
                    .map(user => user.id),
                },
                {
                  onSuccess: () => {
                    setUserIds([
                      {
                        key: Date.now(),
                        id: '',
                      },
                    ]);
                    setIsScheduled(false);
                    setScheduledDate(null);
                    close();
                    onSuccess();
                  },
                },
              );
            }}
            price={estimatedTripData?.estimatedPrice || 0}
          />
        }
        title="Confirmação"
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <>
            <Box
              sx={{
                borderRadius: '30px',
                display: 'flex',
                flexDirection: 'column',
                width: isMobile ? '100%' : '600px',
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '20px',
                    justifyContent: 'start',
                    alignItems: 'center',
                  }}
                >
                  <AddressInput
                    clean={cleanAddressFrom}
                    handleAddress={handleAddressFrom}
                    setValue={setValue}
                    placeholder={addressFrom || 'Origem'}
                    onAddFavoriteAddress={() =>
                      handleSelectFavoriteAddressToOrigin(setValue)
                    }
                    value={addressFrom}
                  />
                  <Box
                    sx={{
                      width: '240px',
                      marginTop: '15px',
                      marginLeft: '40px',
                    }}
                  >
                    <TextField
                      size="small"
                      placeholder="Observação (complemento)"
                      value={observation}
                      onChange={e => setObservation(e.target.value)}
                    />
                  </Box>
                  {!disposition && (
                    <>
                      {stopPoints.length >= 1 &&
                        stopPoints
                          .sort((a, b) => a.order - b.order)
                          .map((stopPoint, i) => (
                            <Box key={stopPoint.order}>
                              <AddressInput
                                clean={cleanStopPoint}
                                handleAddress={handleAddStopPoint}
                                setValue={setStopPoints}
                                placeholder={
                                  stopPoint.address || 'Ponto de parada'
                                }
                                id={stopPoint.order}
                                isStopPoint
                                onAddFavoriteAddress={() =>
                                  handleSelectFavoriteAddressToStopPoint({
                                    setStopPoints,
                                    order: stopPoint.order,
                                  })
                                }
                                value={stopPoint.address}
                              />
                              <Box
                                sx={{
                                  width: '240px',
                                  marginTop: '15px',
                                  marginLeft: '40px',
                                }}
                              >
                                <TextField
                                  size="small"
                                  placeholder={`Observação parada ${i + 1} `}
                                  value={
                                    stopPointsObservation.find(
                                      ({ stopPointId }) =>
                                        stopPointId === stopPoint.order,
                                    )?.observation
                                  }
                                  onChange={e =>
                                    setStopPointsObservation(prev => {
                                      const findStopPoint = prev.find(
                                        data =>
                                          data.stopPointId === stopPoint.order,
                                      );
                                      if (findStopPoint) {
                                        findStopPoint.observation =
                                          e.target.value;
                                        return [...prev];
                                      }
                                      return [
                                        ...prev,
                                        {
                                          stopPointId: stopPoint.order,
                                          observation: e.target.value,
                                        },
                                      ];
                                    })
                                  }
                                />
                              </Box>
                            </Box>
                          ))}
                      <AddressInput
                        clean={cleanAddressTo}
                        handleAddress={handleAddressTo}
                        setValue={setValue}
                        placeholder={addressTo || 'Destino'}
                        onAddFavoriteAddress={() =>
                          handleSelectFavoriteAddressToDestination(setValue)
                        }
                        value={addressTo}
                      />
                    </>
                  )}
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      marginTop: '15px',
                    }}
                  >
                    <FormControlLabel
                      sx={{
                        width: '100%',
                        color: 'gray',
                      }}
                      control={
                        <Checkbox
                          onChange={() => {
                            setDisposition(prev => !prev);
                            setStopPoints([]);
                            setStopPointsObservation([]);
                            setValue('addressTo', '');
                            setValue('finalLat', undefined);
                            setValue('finalLong', undefined);
                          }}
                          checked={disposition}
                        />
                      }
                      label="Motorista à disposição"
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                    }}
                  >
                    <FormControlLabel
                      sx={{
                        width: '100%',
                        color: 'gray',
                      }}
                      control={
                        <Checkbox
                          onChange={() => {
                            setIsScheduled(prev => !prev);
                            setScheduledDate(null);
                          }}
                          checked={isScheduled}
                        />
                      }
                      label="Agendar"
                    />
                    {!disposition && (
                      <StopPoints
                        stopPoints={stopPoints}
                        setStopPoints={setStopPoints}
                      />
                    )}
                  </Box>
                  {isScheduled && (
                    <LocalizationProvider
                      locale={ptLocale}
                      dateAdapter={AdapterDateFns}
                    >
                      <DateTimePicker
                        label="Data inicial"
                        value={scheduledDate}
                        onChange={newValue => {
                          setScheduledDate(newValue);
                        }}
                        renderInput={params => (
                          <TextField
                            sx={{ zIndex: 0 }}
                            size="small"
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      marginTop: '15px',
                    }}
                  >
                    <FormControlLabel
                      sx={{
                        width: '100%',
                        color: 'gray',
                      }}
                      control={
                        <Checkbox
                          onChange={() =>
                            setNeedSpecificCar(prev => {
                              if (prev) {
                                setValue('vehicleCategoryId', undefined);
                                return false;
                              }
                              return true;
                            })
                          }
                          checked={needSpecificCar}
                        />
                      }
                      label="Preciso de um tipo específico de veículo"
                    />
                  </Box>
                  {needSpecificCar && (
                    <Box
                      sx={{
                        width: '100%',
                        marginTop: '-10px',
                      }}
                    >
                      <SelectTypeOfVehicle
                        onChange={e => {
                          setValue('vehicleCategoryId', e.target.value);
                        }}
                      />
                    </Box>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '20px',
                    justifyContent: 'start',
                    alignItems: 'center',
                  }}
                >
                  {selectedCompany && (
                    <>
                      {userIds.map(passenger => (
                        <SelectPassengerAsRenter
                          key={passenger.key}
                          companyId={selectedCompany}
                          passengerId={passenger.id}
                          onAdd={() => openCreatePassenger()}
                          onChange={option => {
                            const alreadySelected = userIds.find(
                              user => user.id === option?.id,
                            );
                            if (alreadySelected) {
                              addNotification({
                                title: 'Esse passageiro já foi selecionado',
                                type: 'warning',
                              });
                              return;
                            }
                            const passengerIndex = userIds.findIndex(
                              data => data.key === passenger.key,
                            );

                            setUserIds([
                              ...userIds.slice(0, passengerIndex),
                              {
                                key: passenger.key,
                                id: option?.id || '',
                              },
                              ...userIds.slice(
                                passengerIndex + 1,
                                userIds.length,
                              ),
                            ]);
                          }}
                          onClose={() => {
                            if (userIds.length === 1) {
                              addNotification({
                                title: 'É necessário ao menos um passageiro',
                                type: 'warning',
                              });
                            }
                            const filteredPassengers = userIds.filter(
                              data => data.key !== passenger.key,
                            );
                            setUserIds(filteredPassengers);
                          }}
                          userIds={userIds}
                        />
                      ))}
                      <Tooltip
                        sx={{ alignSelf: 'flex-end' }}
                        title="Adicionar passageiro"
                      >
                        <IconButton
                          onClick={() => {
                            setUserIds(prev => [
                              ...prev,
                              {
                                id: '',
                                key: Date.now(),
                              },
                            ]);
                          }}
                        >
                          <AddCircle sx={{ color: 'green' }} />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </Grid>
              </Grid>
              <Box
                sx={{
                  padding: '10px 20px',
                  width: isMobile ? '100%' : '300px',
                  marginTop: '-15px',
                  zIndex: 1,
                }}
              >
                <SelectAutoComplete
                  label="Selecione a empresa"
                  selectedOptionId={selectedCompany}
                  setSelectedOption={setSelectedCompany}
                  options={
                    companies?.map(
                      (company: { id: string; corporateName: string }) => ({
                        id: company.id,
                        name: company.corporateName,
                      }),
                    ) || []
                  }
                />
                {selectedCompany && (
                  <SelectSector
                    onClickAdd={() => openCreateSector()}
                    value={sectorId}
                    onChange={e => {
                      setValue('sectorId', e.target.value);
                    }}
                    selectedCompany={selectedCompany}
                  />
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '20px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderBottomRightRadius: '30px',
                  borderBottomLeftRadius: '30px',
                }}
              >
                <LoadingButton
                  disabled={isUpdatingTrip || isChecking}
                  loading={isUpdatingTrip || isChecking}
                  type="submit"
                  variant="contained"
                  color="success"
                  sx={{ marginTop: '10px', borderRadius: '15px' }}
                >
                  Salvar
                </LoadingButton>
              </Box>
            </Box>
          </>
        </form>
      </Box>
    </>
  );
};
