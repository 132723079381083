import { useCallback } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
  ScatterDataPoint,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { eachDayOfInterval } from 'date-fns';
import { useTheme } from '@mui/material';
import { IChatValue } from './Chart';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

const months = [
  'Jan',
  'fev',
  'mar',
  'abr',
  'mai',
  'jun',
  'jul',
  'ago',
  'set',
  'out',
  'nov',
  'dez',
];

type Props = {
  chartValues: IChatValue;
  label: string;
  startDate?: string | Date;
  endDate?: string | Date;
  color?: string;
  gradients?: string[];
  rangeType?: 'year' | 'month' | 'day' | 'alternative';
  customDataset?: {
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor: any;
    fill: boolean;
    yAxisID: 'y' | 'x';
    tension?: number;
  }[];
  legend?: boolean;
};

export function LineChart({
  chartValues,
  label,
  startDate,
  endDate,
  gradients = ['#0D3A90', 'rgba(23, 204, 243, 0.00)'],
  color = 'rgb(53, 162, 235)',
  rangeType = 'year',
  customDataset,
  legend = false,
}: Props) {
  const theme = useTheme();
  let days = [];
  if (
    startDate &&
    endDate &&
    new Date(startDate).getMonth() === new Date(endDate).getMonth()
  ) {
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    const daysBetween = eachDayOfInterval({
      start: startDateObj,
      end: endDateObj,
    });
    days = daysBetween.map(date => date.getDate());
  } else {
    days = Array.from({ length: 31 }, (_, i) => i + 1);
  }

  const hours = Array.from({ length: 24 }, (_, i) => i + 1);

  const selectedLabel = {
    year: months,
    month: days,
    day: hours,
    alternative: Object.keys(chartValues),
  };

  const data: ChartData<'line', (number | ScatterDataPoint | null)[], unknown> =
    {
      labels: selectedLabel[rangeType],
      datasets: customDataset || [
        {
          label,
          data: Object.values(chartValues),
          borderColor: color,
          backgroundColor: context => {
            const { ctx } = context.chart;
            const gradient = ctx.createLinearGradient(0, 0, 0, 250);
            gradient.addColorStop(0, gradients[0]);
            gradient.addColorStop(1, gradients[1]);
            return gradient;
          },
          fill: true,
          yAxisID: 'y',
          tension: 0.4,
        },
      ],
    };
  const optionsBasedOnTheme = useCallback(() => {
    const options: ChartOptions<'line'> = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            precision: 0,
          },
        },
      },
      plugins: {
        legend: {
          display: !!legend,
          position: 'bottom',
          fullSize: true,
          labels: {
            usePointStyle: true,
            textAlign: 'center',
            pointStyle: 'circle',
            padding: 20,
            boxWidth: 8,
            color: theme.palette.mode === 'light' ? '#4D4D4D' : '#DFDFDF',
            font: {
              weight: '500',
              size: 13,
            },
          },
        },
        title: {
          display: true,
          text: label,
          font: {
            size: 20,
            weight: 'bold',
          },
          color: theme.palette.mode === 'light' ? '#4D4D4D' : '#DFDFDF',
        },
      },
      layout: {
        padding: 30,
      },
      hover: {
        intersect: false,
      },
      elements: {
        point: {
          radius: 4,
          hoverRadius: 6,
        },
      },
    };
    return options;
  }, [label, legend, theme.palette.mode]);
  return <Line options={optionsBasedOnTheme()} data={data} />;
}
