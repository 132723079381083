import { useHasPermission } from '@/hooks/useHasPermission';
import { Box, Button, Typography } from '@mui/material';
import { format } from 'date-fns';

export interface Props {
  onConfirm: () => void;
  onClose: () => void;
  price: number;
  isLoading: boolean;
  schuduledDate?: Date | null | undefined;
}

export const RequestTripConfirmation = ({
  onConfirm,
  onClose,
  price,
  isLoading,
  schuduledDate,
}: Props) => {
  const checkIfHasPermissionToSeePopup = useHasPermission(
    'Aviso do tempo de espera do motorista',
  );
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {price ? (
        <Typography>Preço estimado: R$ {price.toFixed(2)}</Typography>
      ) : null}
      {schuduledDate && (
        <Typography>
          Data agendada: {format(schuduledDate, 'dd/MM/yyyy HH:mm')}
        </Typography>
      )}
      {checkIfHasPermissionToSeePopup && (
        <Box>
          <Typography>
            O tempo de espera do motorista é de 15 minutos.
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          width: '100%',
          marginTop: '20px',
        }}
      >
        <Button
          disabled={isLoading}
          sx={{ borderRadius: '16px' }}
          variant="contained"
          color="success"
          onClick={onConfirm}
        >
          Confirmar
        </Button>
        <Button
          disabled={isLoading}
          sx={{ borderRadius: '16px' }}
          variant="contained"
          color="error"
          onClick={onClose}
        >
          Cancelar
        </Button>
      </Box>
    </Box>
  );
};
