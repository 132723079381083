import { useCallback, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
  ScatterDataPoint,
  Filler,
  BarElement,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { eachDayOfInterval } from 'date-fns';
import { useTheme } from '@mui/material';
import { IChatValue } from './Chart';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

const months = [
  'Jan',
  'fev',
  'mar',
  'abr',
  'mai',
  'jun',
  'jul',
  'ago',
  'set',
  'out',
  'nov',
  'dez',
];

type Props = {
  chartValues: IChatValue;
  label: string;
  startDate?: string | Date;
  endDate?: string | Date;
  color?: string;
  gradients?: string[];
  rangeType?: 'year' | 'month' | 'day' | 'alternative';
  customDataset?: {
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor: any;
    fill: boolean;
    yAxisID: 'y' | 'x';
    tension?: number;
  }[];
  legend?: boolean;
};

export function BarChart({
  chartValues,
  label,
  startDate,
  endDate,
  gradients = ['#0D3A90', 'rgba(23, 204, 243, 0.00)'],
  color = 'rgb(53, 162, 235)',
  rangeType = 'year',
  customDataset,
  legend = false,
}: Props) {
  const theme = useTheme();
  let days = [];
  if (
    startDate &&
    endDate &&
    new Date(startDate).getMonth() === new Date(endDate).getMonth()
  ) {
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    const daysBetween = eachDayOfInterval({
      start: startDateObj,
      end: endDateObj,
    });
    days = daysBetween.map(date => date.getDate());
  } else {
    days = Array.from({ length: 31 }, (_, i) => i + 1);
  }

  const hours = Array.from({ length: 24 }, (_, i) => i + 1);

  const selectedLabel = {
    year: months,
    month: days,
    day: hours,
    alternative: chartValues ? Object.keys(chartValues) : [],
  };

  const data: ChartData<'bar', (number | ScatterDataPoint | null)[], unknown> =
    {
      labels: selectedLabel[rangeType],
      datasets: customDataset || [
        {
          label,
          data: chartValues ? Object.values(chartValues) : [],
          borderColor: color,
          backgroundColor: context => {
            const { ctx } = context.chart;
            const gradient = ctx.createLinearGradient(0, 0, 0, 250);
            gradient.addColorStop(0, gradients[0]);
            return gradient;
          },
          yAxisID: 'y',
        },
      ],
    };

  const dataLabelsPlugin = {
    id: 'dataLabels',
    afterDraw: (chart: any) => {
      const { ctx, data } = chart;

      ctx.save();

      data.datasets.forEach((dataset: any, datasetIndex: number) => {
        const meta = chart.getDatasetMeta(datasetIndex);
        if (!meta.hidden) {
          meta.data.forEach((bar: any, index: number) => {
            const value = dataset.data[index];
            if (value !== null && value !== 0) {
              // Definir estilo do texto
              ctx.font = 'bold 11px Arial, sans-serif';
              ctx.fillStyle = theme.palette.mode === 'dark' ? '#FFF' : '#000';
              ctx.textAlign = 'center';
              ctx.textBaseline = 'bottom';

              const { x, y } = bar.getProps(['x', 'y'], true);

              ctx.fillText(Number(value.toString()).toFixed(2), x, y - 5);
            }
          });
        }
      });

      ctx.restore();
    },
  };

  useEffect(() => {
    ChartJS.register(dataLabelsPlugin);

    return () => {
      ChartJS.unregister(dataLabelsPlugin);
    };
  }, []);

  const optionsBasedOnTheme = useCallback(() => {
    const options: ChartOptions<'bar'> = {
      responsive: true,
      maintainAspectRatio: false,
      datasets: {
        bar: {
          maxBarThickness: 100,
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            precision: 0,
          },
        },
      },
      plugins: {
        legend: {
          display: !!legend,
          position: 'bottom',
          fullSize: true,
          labels: {
            usePointStyle: true,
            textAlign: 'center',
            pointStyle: 'circle',
            padding: 20,
            boxWidth: 8,
            color: theme.palette.mode === 'light' ? '#4D4D4D' : '#DFDFDF',
            font: {
              weight: '500',
              size: 13,
            },
          },
        },
        title: {
          display: true,
          text: label,
          font: {
            size: 16,
            weight: 'bold',
          },
          color: theme.palette.mode === 'light' ? '#4D4D4D' : '#DFDFDF',
          padding: {
            top: 10,
            bottom: 20,
          },
        },
      },
      layout: {
        padding: {
          top: 10,
          right: 20,
          bottom: 15,
          left: 20,
        },
      },
      hover: {
        intersect: false,
      },
      elements: {
        point: {
          radius: 4,
          hoverRadius: 6,
        },
      },
    };
    return options;
  }, [label, legend, theme.palette.mode]);

  return <Bar options={optionsBasedOnTheme()} data={data} />;
}
