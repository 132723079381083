import { Box, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { PieChart3DComponent } from '@/features/dashboard/components/PieChar3d';
import { Loading } from '@/components';
import { useCustomTheme } from '@/providers/theme';
import { Chart } from '../Dashboard/Chart';
import {
  IOpexCapexTripData,
  useGetOpexCapexReport,
} from '../../api/getOpexCapexReport';

interface IRequest {
  companyId: string;
  startDate: Date;
  endDate: Date;
}

export const OpexCapex = ({ endDate, startDate, companyId }: IRequest) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { defaultPrimaryColor } = useCustomTheme();
  const { data, isLoading } = useGetOpexCapexReport({
    companyId,
    endDate,
    startDate,
  });

  if (isLoading) {
    return <Loading isLoading={isLoading} />;
  }

  const getTotalTrips = (hourData: IOpexCapexTripData[] | undefined) => {
    return (
      hourData?.reduce((total, company) => {
        const companyTotal = company.data.reduce(
          (sum, value) => sum + value,
          0,
        );
        return total + companyTotal;
      }, 0) || 0
    );
  };

  const totalTrips = getTotalTrips(data?.totalByHour);
  const opexTrips = getTotalTrips(data?.opexByHour);
  const capexTrips = getTotalTrips(data?.capexByHour);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        padding: '20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          height: isMobile ? '100%' : '200px',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        <Paper
          sx={{
            width: '250px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: '8px',
          }}
        >
          <Typography>ATENDIMENTO GERAL POR EMPRESA</Typography>
          <Typography variant="subtitle2" color="text.secondary">
            Total: {totalTrips} viagens
          </Typography>
          <Box sx={{ marginTop: '-30px' }}>
            <PieChart3DComponent
              height={180}
              datasets={
                data?.totalByHour.map(item => {
                  const companyTotal = item.data.reduce(
                    (acc, curr) => acc + curr,
                    0,
                  );
                  return {
                    title: `${item.label} (${companyTotal})`,
                    amount: companyTotal,
                    color: item.backgroundColor || defaultPrimaryColor,
                  };
                }) || []
              }
            />
          </Box>
        </Paper>
        <Paper
          sx={{
            display: 'flex',
            flex: 1,
            height: '100%',
            flexDirection: 'column',
          }}
        >
          <Chart
            chartType="bar"
            label="GERAL | PROGRAMAÇÔES POR HORA"
            rangeType="day"
            customDataset={data?.totalByHour.map(item => ({
              backgroundColor: item.backgroundColor || defaultPrimaryColor,
              data: item.data,
              label: `${item.label} (${item.data.reduce(
                (acc, curr) => acc + curr,
                0,
              )})`,
              fill: false,
              borderColor: item.backgroundColor || defaultPrimaryColor,
              yAxisID: 'y',
              tension: 0.4,
            }))}
            chartValues={[]}
            startDate={new Date()}
            endDate={new Date()}
          />
        </Paper>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          height: isMobile ? '100%' : '200px',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        <Paper
          sx={{
            width: '250px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: '8px',
          }}
        >
          <Typography>OPEX | ATENDIMENTO GERAL POR EMPRESA</Typography>
          <Typography variant="subtitle2" color="text.secondary">
            Total: {opexTrips} viagens
          </Typography>
          <Box sx={{ marginTop: '-30px' }}>
            <PieChart3DComponent
              height={180}
              datasets={
                data?.opexByHour.map(item => {
                  const companyTotal = item.data.reduce(
                    (acc, curr) => acc + curr,
                    0,
                  );
                  return {
                    title: `${item.label} (${companyTotal})`,
                    amount: companyTotal,
                    color: item.backgroundColor || defaultPrimaryColor,
                  };
                }) || []
              }
            />
          </Box>
        </Paper>
        <Paper
          sx={{
            display: 'flex',
            flex: 1,
            height: '100%',
            flexDirection: 'column',
          }}
        >
          <Chart
            chartType="bar"
            label="OPEX | PROGRAMAÇÔES POR HORA"
            rangeType="day"
            customDataset={data?.opexByHour.map(item => ({
              backgroundColor: item.backgroundColor || defaultPrimaryColor,
              data: item.data,
              label: `${item.label} (${item.data.reduce(
                (acc, curr) => acc + curr,
                0,
              )})`,
              fill: false,
              borderColor: item.backgroundColor || defaultPrimaryColor,
              yAxisID: 'y',
              tension: 0.4,
            }))}
            chartValues={[]}
            startDate={new Date()}
            endDate={new Date()}
          />
        </Paper>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          height: isMobile ? '100%' : '200px',
          flexDirection: isMobile ? 'column' : 'row',
        }}
      >
        <Paper
          sx={{
            width: '250px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: '8px',
          }}
        >
          <Typography>CAPEX | ATENDIMENTO GERAL POR EMPRESA</Typography>
          <Typography variant="subtitle2" color="text.secondary">
            Total: {capexTrips} viagens
          </Typography>
          <Box sx={{ marginTop: '-30px' }}>
            <PieChart3DComponent
              height={180}
              datasets={
                data?.capexByHour.map(item => {
                  const companyTotal = item.data.reduce(
                    (acc, curr) => acc + curr,
                    0,
                  );
                  return {
                    title: `${item.label} (${companyTotal})`,
                    amount: companyTotal,
                    color: item.backgroundColor || defaultPrimaryColor,
                  };
                }) || []
              }
            />
          </Box>
        </Paper>
        <Paper
          sx={{
            display: 'flex',
            flex: 1,
            height: '100%',
            flexDirection: 'column',
          }}
        >
          <Chart
            chartType="bar"
            label="CAPEX | PROGRAMAÇÔES POR HORA"
            rangeType="day"
            customDataset={data?.capexByHour.map(item => ({
              backgroundColor: item.backgroundColor || defaultPrimaryColor,
              data: item.data,
              label: `${item.label} (${item.data.reduce(
                (acc, curr) => acc + curr,
                0,
              )})`,
              fill: false,
              borderColor: item.backgroundColor || defaultPrimaryColor,
              yAxisID: 'y',
              tension: 0.4,
            }))}
            chartValues={[]}
            startDate={new Date()}
            endDate={new Date()}
          />
        </Paper>
      </Box>
    </Box>
  );
};
